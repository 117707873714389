body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p,
td,
label,
input,
li {
  font-family: "Raleway";
  font-size: 14px;
}

li {
  margin-left: 15px;
}

input {
  font-family: "Raleway" !important;
}

#root h2,
h1,
h3 {
  font-family: "Montserrat";
  text-transform: uppercase;
  font-size: 14px;
}

.chakra-avatar {
  font-family: "Montserrat";
  font-weight: bold;
}

.chakra-modal__header {
  font-family: "Montserrat";
  text-transform: uppercase;
  font-size: 14px;
}

.chakra-portal h2,
h1,
h3 {
  font-family: "Montserrat";
  text-transform: uppercase;
  font-size: 14px;
}

.chakra-modal__overlay {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.ReactModal__Overlay {
  z-index: 10;
}

.ReactModal__Content {
  width: 300px;
  height: fit-content;
  margin: auto;
  padding: 0px !important;
  text-align: center;
}

.ReactModal__Content h1 {
  margin: 10px 0px;
}

@media (max-width: 768px) {
  .login {
    flex-direction: column;
    height: 100vh;
  }

  .logo {
    flex: initial !important;
    height: 150px !important;
    justify-content: center !important;
  }

  .logo img {
    width: 70% !important;
    max-width: 200px !important;
    margin-right: 0px !important;
  }

  .login-box {
    width: 70% !important;
    max-width: 250px !important;
  }
}
